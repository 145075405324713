import { get } from "@/service/index.js";

// 订单-开票-分页查询
export const reqPagingSettlementSalInvoice = (param) => {
  return get("/coo/settlement/trade/sal-invoice/page", param).then(
    (res) => res
  );
};

// 订单-开票-详情查询
export const reqSelectSettlementSalInvoice = (salInvoiceId) => {
  return get(`/coo/settlement/trade/sal-invoice/${salInvoiceId}`).then(
    (res) => res
  );
};

// 订单-开票-明细-分页查询
export const reqPagingSettlementSalInvoiceItem = (param) => {
  return get(`/coo/settlement/trade/sal-invoice/item/page`, param).then(
    (res) => res
  );
};
