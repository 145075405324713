<template>
  <el-dialog
    append-to-body
    title="生成发票"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
  >
    <div class="cont">
      <div class="cont-tips">
        <p>1.只允许对开票阶段、收票阶段的订单撤销开票；</p>
        <p>2.只允许对尚未开票、开票失败的发票进行撤销。</p>
        <br />
        <span
          >确定要将订单【{{ trade.sheetCode }}】的【{{
            invoices.length
          }}】张发票“撤销开票” 吗？</span
        >
      </div>
      <div slot="footer">
        <el-button
          @click="handleCancelRescind"
          :disabled="loading"
          :loading="loading"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="handleConfirmRescind"
          :disabled="loading"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { reqRescindInvoice } from "@/service/coo/trade.js";

export default {
  name: "SettlementRescindInvoice",
  data() {
    return {
      trade: {
        sheetCode: undefined,
      },
      invoices: {},
      loading: false,
      visible: false,
    };
  },
  methods: {
    // 撤销开票弹窗
    showDialog(trade, invoices) {
      if (!trade) {
        this.toast("撤销失败，请指定撤销开票的订单！", "warning");
        return;
      }

      if (["BI", "CS"].indexOf(trade.sheetStage) == -1) {
        this.toast("撤销失败，此订单阶段不允许撤销！", "warning");
        return;
      }

      if (invoices.length < 1) {
        this.toast("撤销失败，请指定撤销开票的发票！", "warning");
        return;
      }

      if (
        invoices.filter(
          (item) => ["WKP", "KJSB"].indexOf(item.invoiceStatus) == -1
        ).length > 0
      ) {
        this.toast("撤销失败，含不能撤销的发票状态！", "warning");
        return;
      }

      this.trade = trade;
      this.invoices = invoices;

      this.visible = true;
    },
    // 取消生成发票
    handleCancelRescind() {
      this.visible = false;
    },
    // 确认生成发票
    async handleConfirmRescind() {
      this.loading = true;
      const { success, message } = await reqRescindInvoice({
        salInvoiceIds: this.invoices.map((item) => item.id),
      });

      this.loading = false;
      if (success) {
        this.toast(message, "success");
        this.$emit("success");
        this.visible = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  font-size: 14px;
  padding: 30px 40px 30px 40px;

  .cont-tips {
    text-align: left;
    padding-bottom: 30px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}
</style>